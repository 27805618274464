import React from "react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import firstslide from "../Images/master-dashboard-new-2.png";
import secondslide from "../Images/system-dashboard-new-2.png";
import thirdslide from "../Images/reports-new.png";
import fourthslide from "../Images/configuration.png";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Footer from "./Footer";
import Navbar from "./Navbar";

const LandingPage = () => {
  return (
    <div className="overflow-hidden">
      <Navbar />

      <div className="flex flex-col pt-16 md:pt-32 gap-6 md:flex-row md:justify-center lg:py-24 min-h-[85vh]">
        <div className="w-full lg:w-[45vw] px-4">
          <h1 className="w-full text-2xl lg:text-4xl xl:text-5xl font-bold lg:w-[30vw] leading-snug">
            SecurWeave Host Intrusion Detection System
          </h1>

          <ul className="my-8 text-md md:text-xl leading-loose">
            <li>
              <i class="ri-git-commit-line mr-2"></i>Intrusion Detection
            </li>
            <li>
              <i class="ri-git-commit-line mr-2"></i>File Integrity Monitoring
            </li>
            <li>
              <i class="ri-git-commit-line mr-2"></i>Event Management
            </li>
            <li>
              <i class="ri-git-commit-line mr-2"></i>Root Kit Detection &
              Mitigation
            </li>
          </ul>

          <button className="px-6 py-2 bg-black text-white cursor-pointer rounded-lg lg:ml-2">
            Learn More
          </button>
        </div>

        <div className="md:w-[45vw] px-4 mt-6">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            slidesPerView={1}
            navigation
            autoplay
          >
            <SwiperSlide>
              <img src={firstslide}></img>
            </SwiperSlide>
            <SwiperSlide>
              <img src={secondslide}></img>
            </SwiperSlide>
            <SwiperSlide>
              <img src={thirdslide}></img>
            </SwiperSlide>
            <SwiperSlide>
              <img src={fourthslide}></img>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default LandingPage;
